import React from 'react'
// import App from '../../App'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import axios from 'axios'
import './dashboard.css'
import DashboardHeader from '../dashboard-header/dashboard-header'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import {Page, Table, Button} from "tabler-react"
import 'font-awesome/css/font-awesome.min.css';
import {Modal} from 'react-bootstrap';
import queryString from 'query-string';
import { Prompt } from 'react-router';
import * as urls from '../../urls';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';
  
// document.getElementById("toRegister").addEventListener("click", function() {
//     document.getElementById({App}).innerHTML = "<RegisterForm />"
// });

class NewProduct extends React.Component {
    constructor(props) {
        super(props);

        if(localStorage.getItem('token') === undefined || localStorage.getItem('token') === 'undefined') {
            console.log("Gotta sign in :(");
            this.props.history.push("/")
        }

        let token = localStorage.getItem('token') 
        

        this.state = {
            width: 0,
            height: 0,
            jwt: token,
            product_id: null,
            loading: true,
            categories: [],
            showAddCategoryModal: false,
            showAddVariantModal: false,
            showDeleteVariantModal: false,
            showAddDetailModal: false,
            editProductId: '',
            newProductName: '',
            newProductSku: '',
            newProductUPC: '',
            newProductDesc: '',
            newProductQuantity: '',
            newProductPrice: '',
            newProductImage: null,
            newProductCategory: 0,
            newProductWeight: '',
            newProductLength: '',
            newProductWidth: '',
            newProductHeight: '',
            newProductSalePrice: '',
            newProductDetailTitle: '',
            newProductDetailDesc: '',
            variantOf: '',
            invalidProductSubmit: false,
            invalidCategorySubmit: false,
            invalidVariantSubmit: false,
            newVariantSku: '',
            newVariantType: '',
            newVariantValue: '',
            newVariantPrice: '',
            newVariantQuantity: '',
            weightType: 1,
            lengthDimensionType: 1,
            widthDimensionType: 1,
            heightDimensionType: 1,
            variants: [],
            details: [],
            editVariant: '',
            deleteVariant: '',
            deleteProduct: '',
            sumbitted: false
        };

        this.navBlock = false;
        this.submitted = false;
        
        if(this.props.location.search !== undefined) {
            const values = queryString.parse(this.props.location.search);
            var product_id = values.product;
            this.state.product_id = product_id;
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCategoryModalClose = this.handleCategoryModalClose.bind(this);
        this.handleCategoryModalSubmit = this.handleCategoryModalSubmit.bind(this);
        this.showCategoryModal = this.showCategoryModal.bind(this);
        this.handleProductSubmit = this.handleProductSubmit.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.editVariant = this.editVariant.bind(this);
        this.handleVariantDeleteModalClose = this.handleVariantDeleteModalClose.bind(this);
        this.handleVariantDeleteModalSubmit = this.handleVariantDeleteModalSubmit.bind(this);
        this.handleDeleteProduct = this.handleDeleteProduct.bind(this);


    }

    handleDeleteProduct = (event) => {
        this.setState({deleteProduct: this.state.product_id, showDeleteVariantModal: true})
    }

    showCategoryModal = (event) => {
        this.setState({showAddCategoryModal: true})
    }

    showVariantModal = (event) => {

        this.setState({showAddVariantModal: true})
    }

    showVariantDeleteModal = (id) => {
        this.setState({showDeleteVariantModal: true, deleteVariant: id})
    }

    handleVariantDeleteModalClose = (event) => {
        this.setState({showDeleteVariantModal: false})
    }

    showAddDetailModal = (event) => {
        this.setState({showAddDetailModal: true})
    }

    handleVariantDeleteModalSubmit = (event) => {

        if(this.state.deleteVariant !== '' && this.state.product_id === null) {

            var variantId = this.state.variants[this.state.deleteVariant]
            axios({
                method: 'post', 
                url: urls.deleteVariantUrl+variantId+"/",
                withCredentials:+ true,
                body: {},
                headers: {'content-type': 'application/json', 'Authorization': this.state.jwt}
            })
            .then((response) => {
                let product = response.data.payload.product;
                this.setState({newProductSku: product.sku, newProductName: product.name, newProductDesc: product.description, 
                     newProductPrice: product.price, newProductQuantity: product.quantity, newProductWeight: product.weight, variants: response.data.payload.variants, showDeleteVariantModal: false, deleteVariant: ''});
                console.log(this.state);
                
            })
            .catch((error) => {
                console.log(error);
                this.setState({showDeleteVariantModal: true})
            });
        }
        else if(this.state.deleteProduct !=='') {
            axios({
                method: 'get', 
                url: urls.deleteProductUrl+this.state.deleteProduct+"/",
                withCredentials: true,
                headers: {'content-type': 'multipart/form-data', 'Authorization': this.state.jwt}
            })
            .then((response) => {
                this.props.history.push('/dashboard/inventory/')
                
            })
            .catch((error) => {
                console.log(error);
                this.setState({showDeleteVariantModal: false})
            });
        }
        else if(this.state.deleteVariant !== '' && this.state.product_id !== '') {
            var delVar = this.state.variants[this.state.deleteVariant]
            let variants = this.state.variants.filter(function(el) { return el.sku !== delVar.sku; }); 
            this.setState({variants: variants, showDeleteVariantModal: false})
        }
        else {
            this.setState({showDeleteVariantModal: true})
        }
    }

    handleCategoryModalSubmit = (event) => {
        const data = this.state;
        console.log('called on submit')
        console.log(this.state);

        if(data.newCategoryDescription !== '' || data.newCategoryName !== '') {
            console.log("Add category called");
            console.log(data.jwt);
            //Add category submit

            axios({
                method: 'post', 
                url: urls.addCategory,
                data: {'name': data.newCategoryName, "description": data.newCategoryDescription},
                withCredentials: true,
                headers: {'content-type': 'multipart/form-data', 'Authorization': data.jwt}
            })
            .then((response) => {
                console.log(response.data);
                this.setState({showAddCategoryModal: false, categories: this.state.categories.concat(response.data.payload.category)})
                console.log(this.state.categories)
                
            })
            .catch((error) => {
                console.log(error);
            });
        }
        else {
            this.setState({invalidCategorySubmit: true})
        }
    }

    handleCategoryModalClose = (event) => {
        this.setState({showAddCategoryModal: false})
    }

    handleVariantModalClose = (event) => {

        console.log(this.state.editVariant)
        this.setState({newVariantSku: '', newVariantQuantity: '', newVariantPrice:'', newVariantType: '',
            newVariantValue: '', showAddVariantModal: false, editVariant: ''})
    }

    handleVariantModalSubmit = (event) => {
        console.log(this.state.editProductId)
        if((this.state.product_id === null || this.state.product_id === undefined) && this.state.editVariant === '') {

            if(this.state.variants.length === 0) {

                if(this.state.newVariantSku !== '' && this.state.newVariantType !== '' && this.state.newVariantValue !== '' && this.state.newVariantPrice !== ''
                    && this.state.newVariantQuantity !== '' ) {
                        this.setState({variants: this.state.variants.concat([{"sku": this.state.newVariantSku, "variantType": this.state.newVariantType, 
                        "variantValue": this.state.newVariantValue, "price": this.state.newVariantPrice, "quantity": this.state.newVariantQuantity, "type": "manual"}]), newVariantSku: '', newVariantQuantity: '', newVariantPrice:'', newVariantType: '',
                        newVariantValue: '', newProductQuantity: this.state.newVariantQuantity, showAddVariantModal: false})
                }
                else {
                    this.setState({invalidVariantSubmit: true})
                }
            }
            else {
                if(this.state.newVariantSku !== '' && this.state.newVariantType !== '' && this.state.newVariantValue !== '' && this.state.newVariantPrice !== ''
                    && this.state.newVariantQuantity !== '' ) {
                        this.setState({variants: this.state.variants.concat([{"sku": this.state.newVariantSku, "variantType": this.state.newVariantType, 
                        "variantValue": this.state.newVariantValue, "price": this.state.newVariantPrice, "quantity": this.state.newVariantQuantity, "type": "manual"}]), newVariantSku: '', newVariantQuantity: '', newVariantPrice:'', newVariantType: '',
                        newVariantValue: '', newProductQuantity: Number(this.state.newProductQuantity) + Number(this.state.newVariantQuantity), showAddVariantModal: false})
                }
                else {
                    this.setState({invalidVariantSubmit: true})
                }
            }
            
        }
        else {

            if(this.state.editVariant === '') {
                if(this.state.newVariantSku !== '' && this.state.newVariantType !== '' && this.state.newVariantValue !== '' && this.state.newVariantPrice !== ''
                    && this.state.newVariantQuantity !== '' ) {
                        this.setState({variants: this.state.variants.concat([{"sku": this.state.newVariantSku, "variantType": this.state.newVariantType, 
                        "variantValue": this.state.newVariantValue, "price": this.state.newVariantPrice, "quantity": this.state.newVariantQuantity, "type": "manual"}]), newVariantSku: '', newVariantQuantity: '', newVariantPrice:'', newVariantType: '',
                        newVariantValue: '', newProductQuantity: Number(this.state.newProductQuantity) + Number(this.state.newVariantQuantity), showAddVariantModal: false})
                }
                else {
                    this.setState({invalidVariantSubmit: true})
                }
            }
            else {
                console.log(this.state.product_id)
                console.log(this.state.editedVariant)
                var variant = this.state.variants[this.state.editVariant]

                if(variant.type !== undefined) {
                    var editedVariants = this.state.variants
                    var variantDifference = 0;
                    for(var i=0; i < editedVariants.length; i++) {
                        if(i === this.state.editVariant) {
                            variantDifference = this.state.newVariantQuantity - editedVariants[i].quantity;
                            editedVariants[i].sku = this.state.newVariantSku;
                            editedVariants[i].variantType = this.state.newVariantType;
                            editedVariants[i].variantValue = this.state.newVariantValue;
                            editedVariants[i].price = this.state.newVariantPrice;
                            editedVariants[i].quantity = this.state.newVariantQuantity;
                            editedVariants[i].type = "manual"
                            break;
                        }
                    }

                    if(this.state.variants.length === 1) {
                        this.setState({variants: editedVariants, showAddVariantModal: false,  newProductQuantity: Number(this.state.newVariantQuantity), editVariant: '', newVariantSku: '', newVariantPrice: '', newVariantType: '', newVariantQuantity: '', newVariantValue: ''});
                    }
                    else {
                        this.setState({variants: editedVariants, showAddVariantModal: false,  newProductQuantity: Number(variantDifference) + Number(this.state.newProductQuantity), editVariant: '', newVariantSku: '', newVariantPrice: '', newVariantType: '', newVariantQuantity: '', newVariantValue: ''});
                    }
                }
                else {

                    console.log("came to else")

                    var variant = this.state.variants[this.state.editVariant]
                    var bodyFormData = new FormData();
                    bodyFormData.append('sku', this.state.newVariantSku);
                    bodyFormData.append('quantity', this.state.newVariantQuantity);
                    bodyFormData.append('price', this.state.newVariantPrice);
                    bodyFormData.append('variantValue', this.state.newVariantValue);
                    bodyFormData.append('variantType', this.state.newVariantType)

                    axios({
                        method: 'post', 
                        url: urls.updateVariantUrl+variant.id+"/",
                        data: bodyFormData,
                        withCredentials: true,
                        headers: {'content-type': 'multipart/form-data', 'Authorization': this.state.jwt}
                    })
                    .then((response) => {
                        console.log(response.data);
                        let product = response.data.payload.product;
                        this.setState({newProductSku: product.sku, newProductName: product.name, newProductDesc: product.description, editVariant: '', newVariantSku: '', newVariantPrice: '', newVariantType: '', newVariantQuantity: '', newVariantValue: '',
                            newProductPrice: product.price, newProductQuantity: product.quantity, newProductWeight: product.weight, variants: response.data.payload.variants, showAddVariantModal: false});
                        console.log(this.state);
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                }
            }
            
            
        }
    }

    handleChangeFile = (event) => {
        event.preventDefault()
        this.setState({newProductImage: event.target.files[0]});
    }

    handleAddDetail = (event) => {
        if(this.state.newProductDetailTitle !== "" && this.state.newProductDetailDesc !== "") {
            this.setState({details: this.state.details.concat([{"id": "NEW", "title": this.state.newProductDetailTitle, "description": this.state.newProductDetailDesc}]), newProductDetailDesc: "", newProductDetailTitle: ""})
        }
    }

    handleDeleteDetail = (index) => {
        let detail = this.state.details[index]

        if(detail.id === "NEW") {
            var details_mod = [...this.state.details]
            details_mod.splice(index, 1)
            this.setState({details: details_mod})
        }
    }

    componentDidMount() {
        if(this.props.location.search == '') {
            this.setState({loading: false})
        }
        else {
            if(this.state.loading === true) {
                document.getElementById('main-content').style.filter = 'blur(5px)'
            }
        }
    }

    postdata = (event) => {
        const data = this.state;
        console.log('called on submit')
        console.log(this.state);

        var checkVals = ['newProductCategory', 'newProductName', 'newProductDesc', 'newProductQuantity', 'newProductPrice', 'newproductWeight']
        var check = false;
        for(var item in data) {
            if(checkVals.includes(item)) {
                if(data[item] === null || data[item] === '') {
                    console.log(item)
                    check = false;
                    break;
                }
                check = true;
            }
            
        }
        console.log(check)

        if(check && (this.state.product_id === '' || this.state.product_id === undefined)) {
            console.log(data.jwt);
            //Add category submit
            var body = {
                'category_id': data.newProductCategory,
                'name': data.newProductName,
                'description': data.newProductDesc,
                'sku': data.newProductSku,
                'upc': data.newProductUPC,
                'quantity': data.newProductQuantity,
                'width': data.newProductWidth * data.widthDimensionType,
                'length': data.newProductLength * data.lengthDimensionType,
                'height': data.newProductHeight * data.heightDimensionType,
                'price': data.newProductPrice,
                'image': data.newProductImage,
                'weight': data.newProductWeight * data.weightType,
                'details': this.state.details,
                'access_token': data.jwt
            }

            axios({
                method: 'post', 
                url: urls.addProduct,
                data: body,
                withCredentials: true,
                headers: {'content-type': 'application/json', 'Authorization': data.jwt}
            })
            .then((response) => {
                console.log(response.data);
                this.setState({submitted: true})
                this.props.history.push('/dashboard/inventory/');
                
            })
            .catch((error) => {
                console.log(error);
            });
        } else if(check && this.state.product_id !== '') {
            console.log("edit product call reached")
            var bodyFormData = new FormData();
            bodyFormData.append('category_id', data.newProductCategory);
            bodyFormData.append('name', data.newProductName);
            bodyFormData.append('description', data.newProductDesc);
            bodyFormData.append('sku', data.newProductSku);
            bodyFormData.append('quantity', data.newProductQuantity);
            bodyFormData.append('price', data.newProductPrice);
            bodyFormData.append('image', data.newProductImage);
            bodyFormData.append('weight', data.newProductWeight * data.weightType)
            bodyFormData.append('variants', JSON.stringify(this.state.variants));
            bodyFormData.append('details', this.state.details);
            bodyFormData.append('access_token', data.jwt);


            var body = {
                'category_id': data.newProductCategory,
                'name': data.newProductName,
                'description': data.newProductDesc,
                'sku': data.newProductSku,
                'upc': data.newProductUPC,
                'quantity': data.newProductQuantity,
                'width': data.newProductWidth * data.widthDimensionType,
                'length': data.newProductLength * data.lengthDimensionType,
                'height': data.newProductHeight * data.heightDimensionType,
                'price': data.newProductPrice,
                'image': data.newProductImage,
                'weight': data.newProductWeight * data.weightType,
                'details': this.state.details,
                'access_token': data.jwt
            }

            console.log(body);

            axios({
                method: 'post', 
                url: urls.editProduct+"/"+this.state.product_id,
                data: body,
                withCredentials: true,
                headers: {'content-type': 'application/json', 'Authorization': data.jwt}
            })
            .then((response) => {
                console.log(response.data);
                this.setState({submitted: true})
                // this.props.history.push('/dashboard/inventory/');
                
            })
            .catch((error) => {
                console.log(error);
            });
        }
        else {
            this.setState({invalidProductSubmit: true});
        }
    }

    handleProductSubmit = (event) => {
        if(this.state.newProductDetailTitle !== "" && this.state.newProductDetailDesc !== "") {
            console.log("reached here")
            this.setState({details: this.state.details.concat([{"id": "NEW", "title": this.state.newProductDetailTitle, "description": this.state.newProductDetailDesc}]), newProductDetailDesc: "", newProductDetailTitle: ""}, () => {this.postdata()})
        }
        else {
            this.postdata()
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
        console.log(event.target.name)
        this.navBlock = true;
    }

    handleDetailChangeTitle = index => event => {
        const newDetails = this.state.details.map((value, detIndex) => {
            if (index !== detIndex) return value;
            return { ...value, title: event.target.value}
        });
        this.setState({details: newDetails})
    }

    handleDetailChangeDescription = index => event => {
        const newDetails = this.state.details.map((value, detIndex) => {
            if (index !== detIndex) return value;
            return { ...value, description: event.target.value}
        });
        this.setState({details: newDetails})
    }

    handleSubmit = (event) => {
        event.preventDefault();
    }

    componentWillUnmount() {
        const data = this.state;
        console.log('called on submit')
        console.log(this.state);

        if(this.state.submitted) {
            this.navBlock = false
        }
        else {
            for(var item in data) {
                if(data[item] === null || data[item] === '') {
                    console.log(item)
                    this.navBlock = true;
                    break;
                }
                this.navBlock = false;
            }
        }
    
    }

    componentWillUpdate = () => {
        if (this.navBlock && !this.state.submitted) {
          window.onbeforeunload = () => true
        } else {
          window.onbeforeunload = undefined
        }
    }

    editVariant = (index) => {
        var variant = this.state.variants[index]
        console.log(variant);
        this.setState({newVariantSku: variant.sku, newVariantQuantity: variant.quantity, newVariantPrice: variant.price, newVariantType: variant.variantType,
        newVariantValue: variant.variantValue, showAddVariantModal: true, editVariant: index})
    }

    componentWillMount() {
        var token = localStorage.getItem('token');

        if(this.props.location.search !== '') {
            axios({
                method: 'get', 
                url: urls.getProduct+ "/" + this.state.product_id,
                withCredentials: true,
                headers: {'content-type': 'multipart/form-data', 'Authorization': token}
            })
            .then((response) => {
                console.log(response.data);
                let product = response.data.payload.product;
                console.log(product)
                document.getElementById('main-content').style.filter = 'blur(0px)'
                this.setState({loading: false, newProductSku: product.sku, newProductUPC: product.upc, newProductName: product.name, newProductDesc: product.description, newProductCategory: product.category,
                    newProductPrice: product.price, newProductQuantity: product.quantity, newProductWeight: product.weight, newProductLength: product.length, newProductWidth: product.width, newProductHeight: product.height, details: product.details});
                console.log(this.state);
    
            })
            .catch(error => {
                console.log(error.response.status)
                console.log(error.response)
                console.log(error.response.data)

                if(error.response.status === 400) {
                    this.props.history.push('/dashboard/inventory')
                }

            });
    
        }

        axios({
            method: 'get', 
            url: urls.getVendorInfo,
            withCredentials: true,
            headers: {'content-type': 'multipart/form-data', 'Authorization': token}
        })
        .then((response) => {
            console.log(response.data);
            if(response.data.payload.profile.active === false) {
                this.props.history.push({pathname: "/store-register/", state:{jwt: token}});
            }
            this.setState({categories: response.data.payload.profile.categories});
            console.log(this.state);
        })
        .catch(error => {
            console.log(error)
            this.props.history.push('/')
        });
    }

    render() {
        return (
            <div>
                {this.state.loading && (
                    <div style={{position: 'absolute', left: 0, right: 0, width: '100%', height: '100%', zIndex: 1000, backgroundcolor: 'blue', textAlign: 'center', marginTop: '20em'}}>
                        <ClipLoader
                        sizeUnit={"px"}
                        size={150}
                        color={'#F7941D'}
                        loader
                        loading={this.state.loading}
                        />
                    </div> 
                )}
                
                <div id="main-content">
                <Prompt
                    when={this.navBlock}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <div className="page-header">
                    {this.props.location.search === '' &&
                        <h1 className="page-title">Add Product</h1> ||
                        <h1 className="page-title">Edit Product</h1>
                    }
                </div>
                <div className="col d-flex justify-content-center">
                    <div className="card col-8 shadow" >
                        <div className="card-header" style={{ marginTop: 5 }}>
                            <h5 style={{paddingBottom: 0, marginBottom: 0}}>Product Information</h5>
                        </div>
                        <div className="card-body" style={{paddingLeft: 20, paddingRight: 20}}>
                            <form>
                                <div className="row" style={{marginBottom: 16}}>
                                    <div className="col-md-6">
                                        <label for="SKU">SKU*</label>
                                        <input type="text" className="form-control" name="newProductSku" value={this.state.newProductSku} onChange={this.handleChange}
                                        id="sku" placeholder="Product SKU"/>                                    </div>
                                    <div className="col-md-6">
                                        <label for="upc">UPC*</label>
                                        <input type="text" className="form-control" name="newProductUPC" value={this.state.newProductUPC} onChange={this.handleChange}
                                        id="upc" placeholder="Product UPC"/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label for="name">Name*</label>
                                    <input type="text" className="form-control" name="newProductName" value={this.state.newProductName} onChange={this.handleChange}
                                    id="name" placeholder="Product Name"/>
                                </div>
                                <div className="form-group">
                                    <label>Image*</label>
                                    <input style={{height: 'auto'}} className="form-control" id="image" type="file" multiple={ false } ref={(input) =>{ this.inputElement = input; }} accept=".jpg, .jpeg, .png" 
                                        onChange={this.handleChangeFile} placeholder="Store Logo" name="newProductImage" />
                                </div>
                                <div className="form-group">
                                    <label for="description">Description*</label>
                                    <textarea type="text" className="form-control" name="newProductDesc" value={this.state.newProductDesc} onChange={this.handleChange} 
                                    id="description" placeholder="Product Description"/>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>

                <div className="col d-flex justify-content-center">
                    <div className="card col-8 shadow" >
                        <div className="card-header" style={{ marginTop: 5 }}>
                            <h5 style={{paddingBottom: 0, marginBottom: 0}}>Metrics</h5>
                        </div>
                        <div className="card-body" style={{paddingLeft: 20, paddingRight: 20}}>
                            <form>
                                <div className="form-group">
                                    <label for="price">Price*</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                        <span class="input-group-text" id="dollarSign">$</span>
                                        </div>
                                        <input type="number" min="0.01" step="0.01" class="form-control" id="price" value={this.state.newProductPrice} 
                                        name="newProductPrice" onChange={this.handleChange} placeholder="Product Price" aria-describedby="dollarSign" required/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label for="price">Weight*</label>
                                    <div class="input-group">
                                        <input type="number" min="0.01" step="0.01" value={this.state.newProductWeight} name="newProductWeight" onChange={this.handleChange} class="form-control" placeholder="Product Weight"/>
                                        <div class="input-group-append">
                                            <select className="form-control" value={this.state.weightType} name="weightType" onChange={this.handleChange} style={{height: '2.275rem'}}>
                                                <option value="1">lb</option>
                                                <option value="0.0625">oz</option>
                                                <option value="2.20462">kg</option>
                                                <option value="0.00220462">g</option>
                                            </select> 
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{marginBottom: 20}}>
                                    <div className="col-md-4">
                                        <label for="length">Length</label>
                                        <div class="input-group">
                                            <input type="number" min="0.01" step="0.01" value={this.state.newProductLength} name="newProductLength" onChange={this.handleChange} class="form-control" placeholder="Product Length"/>
                                            <div class="input-group-append">
                                                <select className="form-control" value={this.state.lengthDimensionType} name="lengthDimensionType" onChange={this.handleChange} style={{height: '2.275rem'}}>
                                                    <option value="1">in</option>
                                                    <option value="2.54">cm</option>
                                                    <option value="39.3701">m</option>
                                                    <option value="12">ft</option>
                                                </select> 
                                            </div>
                                        </div>                                  
                                    </div>
                                    <div className="col-md-4">
                                        <label for="length">Width</label>
                                        <div class="input-group">
                                            <input type="number" min="0.01" step="0.01" value={this.state.newProductWidth} name="newProductWidth" onChange={this.handleChange} class="form-control" placeholder="Product Width"/>
                                            <div class="input-group-append">
                                                <select className="form-control" value={this.state.widthDimensionType} name="widthDimensionType" onChange={this.handleChange} style={{height: '2.275rem'}}>
                                                    <option value="1">in</option>
                                                    <option value="2.54">cm</option>
                                                    <option value="39.3701">m</option>
                                                    <option value="12">ft</option>
                                                </select> 
                                            </div>
                                        </div>  
                                    </div>
                                    <div className="col-md-4">
                                        <label for="height">Height</label>
                                        <div class="input-group">
                                            <input type="number" min="0.01" step="0.01" value={this.state.newProductHeight} name="newProductHeight" onChange={this.handleChange} class="form-control" placeholder="Product Height"/>
                                            <div class="input-group-append">
                                                <select className="form-control" value={this.state.heightDimensionType} name="heightDimensionType" onChange={this.handleChange} style={{height: '2.275rem'}}>
                                                    <option value="1">in</option>
                                                    <option value="2.54">cm</option>
                                                    <option value="39.3701">m</option>
                                                    <option value="12">ft</option>
                                                </select> 
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                
                                <div className="form-group">
                                    <label for="quantity">Quantity*  </label>
                                    <input type="number" className="form-control" name="newProductQuantity" value={this.state.newProductQuantity} onChange={this.handleChange}
                                    id="quantity" placeholder="Product Quantity"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="col d-flex justify-content-center">
                    <div className="card col-8 shadow" >
                    <div className="card-header" style={{ marginTop: 5 }}>
                            <h5 style={{paddingBottom: 0, marginBottom: 0}}>Additional Details</h5>
                        </div>
                        <div className="card-body" style={{paddingLeft: 20, paddingRight: 20}}>
                            <div className="row">
                                <div className="col-md-5">
                                    <p style={{marginBottom: 5}}>Title</p>
                                </div>
                                <div className="col-md-6">
                                    <p style={{marginBottom: 5}}>Description</p>
                                </div>
                                <div className="col-md-1">
                                </div>
                            </div>

                    
                            {this.state.details.map((value, index) => {
                                return (
                                    <div className="row" style={{marginBottom: 7}}>
                                        <div className="col-md-5">
                                            <input className="form-control" placeholder="Ex: ISBN" value={value.title} onChange={this.handleDetailChangeTitle(index)}></input>
                                        </div>
                                        <div className="col-md-6">
                                            <input className="form-control" placeholder="Ex: 0000000000" value={value.description} onChange={this.handleDetailChangeDescription(index)}></input>
                                        </div>
                                        <div className="col-md-1">
                                            <button type="button" class="btn btn-outline-dashboard" onClick={() => this.handleDeleteDetail(index)} style={{padding: 2}}><h2 style={{margin: 0}}>-</h2></button>
                                        </div>
                                    </div>
                                ) 
                            })}
                            
                            <div className="row" style={{marginBottom: '1rem'}}>
                                <div className="col-md-5">
                                    <input className="form-control" placeholder="Ex: ISBN" name="newProductDetailTitle" value={this.state.newProductDetailTitle} onChange={this.handleChange} required></input>
                                </div>
                                <div className="col-md-6">
                                    <input className="form-control" placeholder="Ex: 0000000000" name="newProductDetailDesc" value={this.state.newProductDetailDesc} onChange={this.handleChange} required></input>
                                </div>
                                <div className="col-md-1">
                                    <button type="button" class="btn btn-outline-dashboard" onClick={this.handleAddDetail} style={{padding: 2}}><h2 style={{margin: 0}}>+</h2></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col d-flex justify-content-center">
                    <div className="card col-8 shadow" >
                        <div className="card-header" style={{ marginTop: 5 }}>
                            <div className="row" style={{width: '100%'}}>
                                <div className="col-sm" style={{margin: 'auto'}}>
                                    <h5 style={{paddingBottom: 0, marginBottom: 0}}>Category</h5>
                                </div>
                                <div className="col-sm">
                                    <button className="btn btn-outline-primary btn-outline-dashboard" style={{float: "right", border: 0, outline: 0}}
                                    onClick={this.showCategoryModal}>+ Add Category</button>
                                </div>
                            </div>
                        </div>                        
                        <div className="card-body" style={{paddingLeft: 20, paddingRight: 20}}>
                            <form>
                                <div className="form-group">
                                    <label for="category">Category*</label>
                                    <select className="form-control" id="category" placeholder="Product Category" name="newProductCategory" onChange={this.handleChange} value={this.state.newProductCategory}>
                                        <option selected value=''>Choose Category</option>
                                            {this.state.categories.map((value, index) => {
                                                return <option value={value.id}>{value.name}</option>
                                            })}
                                    </select>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* <div className="col d-flex justify-content-center">
                    <div className="card col-8 shadow" >
                        <div className="card-header" style={{ marginTop: 5 }}>
                            <div className="row" style={{width: '100%'}}>
                                <div className="col-sm" style={{margin: 'auto'}}>
                                    <h5 style={{paddingBottom: 0, marginBottom: 0}}>Variants</h5>
                                </div>
                                <div className="col-sm">
                                    <button className="btn btn-outline-primary btn-outline-dashboard" style={{float: "right", border: 0, outline: 0}}
                                    onClick={this.showVariantModal}>+ Add Variant</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={{paddingLeft: 20, paddingRight: 20}}>
                            <form>
                                <div className="form-group">
                                    <table className="table card-table table-vcenter">
                                        <thead className="thead-light">
                                            <tr className="">
                                                <th style={{width: '20%'}}>SKU</th>
                                                <th style={{width: '20%'}}>Type</th>
                                                <th style={{width: '20%'}}>Value</th>
                                                <th style={{width: '20%'}}>Price</th>
                                                <th style={{width: '10%'}}>Quantity</th>
                                                <th style={{width: '5%'}}></th>
                                                <th style={{width: '5%'}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.variants.map((value, index) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {value.sku}
                                                        </td>
                                                        <td>
                                                            {value.variantType}
                                                        </td>
                                                        <td>
                                                            {value.variantValue}
                                                        </td>
                                                        <td>
                                                            {value.price}
                                                        </td>
                                                        <td>
                                                            {value.quantity}
                                                        </td>
                                                        <td style={{padding: 0}}>
                                                            <button className="btn" type="button" onClick={() => this.editVariant(index)}><i className="fa fa-edit"></i></button>
                                                        </td>
                                                        <td style={{padding: 0}}>
                                                        <button className="btn" type="button" onClick={() => this.showVariantDeleteModal(index)}><i className="fa fa-trash"></i></button>
                                                        </td>
                                                    </tr>) 
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> */}
               
                <div className="col d-flex justify-content-center">
                    <div className="col-8" >
                        <hr style={{marginTop: 0, marginBottom: 10}}></hr>
                        <p>*  Required fields</p>
                        {this.props.location.search !== '' &&
                            <button className="btn btn-danger" onClick={this.handleDeleteProduct} style={{float: 'left', marginBottom: 30}}>Delete Product</button>
                        }
                        <button className="btn btn-success" style={{float: 'right', marginBottom: 30}} onClick={this.handleProductSubmit}>Save Product</button>
                    </div>
                </div>
                
                <Modal show={this.state.showAddCategoryModal} backdrop='static' onHide={this.handleCategoryModalClose}>
                    <Modal.Header>
                        <Modal.Title>Add Category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.invalidCategorySubmit && 
                            <div class="alert alert-danger" role="alert" style={{textAlign: 'center'}}>
                                * All fields are required *
                            </div>
                        }
                        <form>
                            <label class="form-label" for="cateogry_name">Name</label>
                            <input class="form-control" name="newCategoryName" type="text" placeholder="Category Name" value={this.state.value}
                            onChange={this.handleChange}></input>
                            
                            <label class="form-label" for="category_description" style={{marginTop: 10}}>Description</label>
                            <input class="form-control" name="newCategoryDescription" type="text" placeholder="Category Description" value={this.state.value}
                            onChange={this.handleChange}></input>
                            
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="btn btn-outline-secondary" onClick={this.handleCategoryModalClose}>
                        Close
                        </Button>
                        <Button variant="primary" className="btn btn-outline-primary btn-outline-dashboard" onClick={this.handleCategoryModalSubmit}>
                        Save Category
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showAddVariantModal} backdrop='static' onHide={this.handleVariantModalClose}>
                    <Modal.Header>
                        {this.state.editVariant === '' &&
                            <Modal.Title>Add Variant</Modal.Title> ||
                            <Modal.Title>Edit Variant</Modal.Title>
                        }
                        
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.invalidVariantSubmit && 
                            <div class="alert alert-danger" role="alert">
                                * Missing required fields *
                            </div>
                        }
                        <form>
                            <label class="form-label" for="variant_sku">SKU</label>
                            <input class="form-control" name="newVariantSku" type="text" placeholder="Variant SKU" value={this.state.newVariantSku}
                            onChange={this.handleChange}></input>
                            <br></br>
                            <label class="form-label" for="variant_type">Type</label>
                            <input class="form-control" name="newVariantType" type="text" placeholder="Variant Type (ex: Color, Size)" value={this.state.newVariantType}
                            onChange={this.handleChange}></input>
                            <br></br>
                            <label class="form-label" for="variant_value">Value</label>
                            <input class="form-control" name="newVariantValue" type="text" placeholder="Variant Value" value={this.state.newVariantValue}
                            onChange={this.handleChange}></input>
                            <br></br>
                            <label class="form-label" for="variant_price">Added Cost</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                <span class="input-group-text" id="dollarSign">$</span>
                                </div>
                                <input type="number" min="0.01" step="0.01"  class="form-control" name="newVariantPrice" id="price" value={this.state.newVariantPrice} onChange={this.handleChange} placeholder="Variant Price" aria-describedby="dollarSign" required/>
                            </div>
                            <br></br>
                            <label class="form-label" for="variant_quantity">Quantity</label>
                            
                            <input class="form-control" name="newVariantQuantity" type="number" placeholder="Variant Quantity" value={this.state.newVariantQuantity}
                            onChange={this.handleChange}></input>
                            <br></br>
                        
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="btn btn-outline-secondary" onClick={this.handleVariantModalClose}>
                        Close
                        </Button>
                        <Button variant="primary" className="btn btn-outline-primary btn-outline-dashboard" onClick={this.handleVariantModalSubmit}>
                        Save Variant
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showDeleteVariantModal} backdrop='static' onHide={this.handleVariantDeleteModalClose}>
                    <Modal.Header>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       <h6>This action cannot be undone.</h6>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="btn btn-outline-secondary" onClick={this.handleVariantDeleteModalClose}>
                        Close
                        </Button>
                        <Button variant="primary" className="btn btn-danger" onClick={this.handleVariantDeleteModalSubmit}>
                        Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

            
                </div>  
            </div>
            
        );
    }
  }

  export default NewProduct
import React from 'react'
import axios from 'axios'
import '../dashboard/dashboard.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import {Page, Table, Button} from "tabler-react"
import 'font-awesome/css/font-awesome.min.css';
import {Modal} from 'react-bootstrap'
import * as urls from '../../urls';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';
import DataTable from 'react-data-table-component';


const columns = [
  {
    name: 'Order Number',
    sortable: false,
    cell: row => <div style={{fontSize: 14}}>{row.title}</div>,
  },
  {
    name: 'Order Status',
    sortable: false,
    cell: row => <div style={{fontSize: 14}}>{row.status}</div>,
    center: true,
  },
  {
    name: 'Created',
    cell: row => <div style={{fontSize: 14}}>{row.created}</div>,
    sortable: false,
    right: true,
  },
];
  
// The row data is composed into your custom expandable component via the data prop
const ExpanableComponent = ({ data }) => (
    <div className="table-responsive" style={{paddingTop: 20, backgroundColor: '#f5f7fb'}}>
        <h5 style={{marginLeft: 64}}>Items: </h5>
        <div style={{paddingLeft: 64, paddingRight: 20}}>
            <table className="table card-table table-vcenter" style={{textAlign: 'left'}}>
                <thead className="">
                    <tr className="">
                        <th style={{width: '10%'}}>SKU</th>
                        <th style={{width: '10%'}}>UPC</th>
                        <th style={{width: '12%'}}>Name</th>
                        <th style={{width: '10%'}}>Item Price</th>
                        <th style={{width: '10%'}}>QTY</th>
                        <th style={{width: '10%'}}>Subtotal</th>
                    </tr>
                </thead>
                <tbody>

                    {data.items.map((item, i) => {
                        return(
                            <tr> 
                                <td>
                                    {item.sku}
                                </td>
                                <td>
                                    {item.upc}
                                </td>
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    ${item.item_price}
                                </td>
                                <td>
                                    {item.quantity}
                                </td>
                                <td>
                                    ${item.subtotal}
                                </td>
                                
                            </tr>
                        )
                    })}             
                </tbody>
                            
            </table>
        </div>
        <hr style={{marginBottom: 5, width: '100%'}}></hr>
        <div style={{textAlign: 'right', paddingRight: 40}}>
            <h6 style={{fontWeight: 'normal'}}>Tax: ${Number(data.tax).toFixed(2)}</h6>
            <h6>Total: ${Number(data.total).toFixed(2)}</h6>
        </div>
    </div>
);

class Orders extends React.Component {
    constructor(props) {
        super(props);
        console.log("Reached dashboard-table");

        if(localStorage.getItem('token') === undefined || localStorage.getItem('token') === 'undefined') {
            console.log("Gotta sign in :(");
            this.props.history.push("/");
        }

        let token = localStorage.getItem('token')

        this.state = {
            jwt: token,
            retailer: {},
            orders: [],
            search: '',
            loading: true,
            orderCategories: ['Awaiting', 'Accepted'],
            shownCategory: [],
            filterCategoryInt: 0,
            loading: null,

        };

        this.retailer = {
            retailerName: '',
            retailerImageUrl: '',
            retailerLocationName: '',
        };

        console.log(this.state);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSearch = (event) => {
        this.setState({search: event.target.value})
    }

    handleChange = (event) => {
        event.preventDefault()
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit = (event) => {
        event.preventDefault();
    }

    componentWillMount() {
        console.log(this.props);
        var token = localStorage.getItem('token');
        this.setState({loading: true})
        this.fetchAllOrders(token);
    }

    isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
          someDate.getMonth() == today.getMonth() &&
          someDate.getFullYear() == today.getFullYear()
    }

    parseTime(djangoTime) {
        var date = new Date(djangoTime);
        if(this.isToday(date)) {
            return date.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'})
        }
        else {
            return date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'})
        }
    }

    fetchAllOrders(token) {
        console.log(this.state.orders)
        axios({
            method: 'get', 
            url: urls.getAllOrders,
            withCredentials: true,
            headers: {'content-type': 'application/json', 'Authorization': token}
        })
        .then((response) => {
            console.log(response.data);
            if(response.data.status === 'successful') {
                var orders = response.data.payload.orders;
                console.log(orders)
                var objs = [];
                for(var i = 0; i < orders.length; i++) {
                    var obj = {};
                    var order = orders[i];
                    obj.id = order.id;
                    obj.title = "Order " + obj.id;
                    obj.created = this.parseTime(order.created_at);
                    obj.status = order.status;
                    obj.tax = 20;
                    obj.total = order.order_price
                    
                    var itemObjs = [];
                    for(var j = 0; j < order.items.length; j++) {
                        var item = order.items[j]
                        var itemObj = {}
                        itemObj.sku = item.product_sku;
                        itemObj.upc = item.product_upc;
                        itemObj.name = item.product_name;
                        itemObj.item_price = item.product_price;
                        itemObj.quantity =  item.item_quantity;
                        itemObj.subtotal = item.item_price;

                        itemObjs.push(itemObj)
                    }
                    obj.items = itemObjs;
                    objs.push(obj);
                }

                console.log(objs)
                this.setState({orders: objs, shownCategory: objs, loading: false})
            }
        })
        .catch(error => {
            console.log(error);
            this.setState({loading: false})
            // this.props.history.push('/');
        });
    }

    filtercategory = (event) => {
        console.log(event.target.value)
        if(event.target.value === undefined || event.target.value ==='') {
            this.setState({shownCategory: this.state.orders})
        }
        else {
            var filteredOrders = []
            for(var i=0; i < this.state.orders.length; i++) {
                var order = this.state.orders[i];
                
                if(order.status === 'created' && this.state.orderCategories[event.target.value] === 'Awaiting') {
                    filteredOrders.push(order);
                }
            }

            this.setState({shownCategory: filteredOrders})
            // let cats = this.state.categories[event.target.value]
            // console.log([cats])
            // this.setState({shownCategory: [cats]})
            // console.log(this.state.shownCategory)
        }
        
    }
    
    render() {

        return (
            <div>
                <div className="page-header">
                    <h1 className="page-title">Orders</h1>
                </div>

                <div className="col col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row" style={{width: '100%', marginTop: 5}}>
                                <div className="col-sm">
                        
                                    <select className="btn btn-secondary" value={this.state.value} onChange={this.filtercategory} name="filterCategoryInt" >
                                        <option value="">All Orders</option>
                                        {this.state.orderCategories.map((value, index) => {
                                            return <option value={index}>{value}</option>
                                        })}
                                    </select> 
                                </div>
                                
                                <div className="col-sm">
                                    
                                        <input style={{width: 250, height: 'auto', float: "right", border: 0, outline: 0, 
                                        background: 'transparent', borderBottom: 1, borderBottomColor: 'gray', borderBottomStyle:'solid', paddingBottom: 4}} type="text" 
                                        placeholder="Search Order Number" aria-label="Search" name="search" value={this.state.value} onChange={this.handleSearch}/>
                                </div>
                                
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={this.state.shownCategory}
                            progressPending={this.state.loading}
                            noHeader
                            selectableRowsComponentProps={{ inkDisabled: true }}
                            onRowSelected={this.handleChange}
                            expandableRows
                            expandableRowsComponent={<ExpanableComponent />}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Orders
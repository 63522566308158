import React from 'react'
// import App from '../../App'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import axios from 'axios'
import './dashboard.css'
import DashboardHeader from '../dashboard-header/dashboard-header'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import {Page, Table, Button} from "tabler-react"
import 'font-awesome/css/font-awesome.min.css';
import {Modal} from 'react-bootstrap'
import NewProduct from './dashboard-new-product';
import DashboardTable from './dashboard-table';
import {Switch} from 'react-router';
import * as urls from '../../urls';
import Orders from '../orders/orders';
  
// document.getElementById("toRegister").addEventListener("click", function() {
//     document.getElementById({App}).innerHTML = "<RegisterForm />"
// });

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.header = React.createRef();

        if(localStorage.getItem('token') === undefined || localStorage.getItem('token') === 'undefined') {
            console.log("Gotta sign in :(");
            this.props.history.push("/")
        }

        let token = localStorage.getItem('token')

        this.state = {
            jwt: token,
            retailer: {},
            categories: [],
            activeTab: 'inventory',
        };

        this.retailer = {
            retailerName: '',
            retailerImageUrl: '',
            retailerLocationName: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event) => {
        event.preventDefault()
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit = (event) => {
        event.preventDefault();
    }


    componentWillMount() {
        var token = localStorage.getItem('token');

        var bodyFormData = new FormData();
        bodyFormData.append('access_token', token);
        axios({
            method: 'get', 
            url: urls.getVendorInfo,
            withCredentials: true,
            headers: {'content-type': 'applicaton/json', 'Authorization': token}
        })
        .then((response) => {
            console.log(response.data);
            if(response.data.payload.profile.active === false) {
                this.props.history.push({pathname: "/store-register/", state:{jwt: token}});
            }
            this.setState({retailer: {
                retailerName: response.data.payload.profile.firstname + " " + response.data.payload.profile.lastname,
                retailerImage: "http://localhost:8000"+ response.data.payload.profile.store_logo,
                locationName: response.data.payload.profile.location_name,
            }, categories: response.data.payload.profile.categories});
            console.log(this.state);
        })
        .catch(error => {
            console.log(error)
            this.props.history.push('/')
        });

    }

    render() {
        return (
            <div>
                <DashboardHeader ref={this.header} history={this.props.history} 
                    retailer={this.state.retailer}/>
                    <div className="container">
                        <Switch>
                            <Route
                                path='/dashboard/inventory'
                                exact
                                render={(props) => <DashboardTable { ...this.props } />}
                                
                            />
                            <Route
                                path='/dashboard/product/'
                                render={(props) => <NewProduct { ...this.props } />}
                            />

                            <Route
                                path='/dashboard/orders/'
                                render={(props) => <Orders { ...this.props } />}
                            />

                        </Switch>
                            
                    </div>
            </div>
            
        );
    }
  }

  export default Dashboard
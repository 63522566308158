import React from 'react'
import './login.css'
// import RegisterForm from '../register/register.jsx'
// import App from '../../App'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import axios from 'axios'
import { request } from 'http';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { withRouter } from "react-router";
import * as urls from '../../urls'

// document.getElementById("toRegister").addEventListener("click", function() {
//     document.getElementById({App}).innerHTML = "<RegisterForm />"

//Added useless comment
// });

class LoginForm extends React.Component {
    constructor(props) {
      super(props);
      this.props = props;
      console.log(localStorage.getItem('token'))
      if(localStorage.getItem('token') === undefined || localStorage.getItem('token') === 'undefined' || localStorage.getItem('token') === null) {
          console.log("Gotta sign in :(")
      }
      else {
          var token = localStorage.getItem('token');
          axios({
            method: 'get', 
            url: urls.getVendorInfo,
            withCredentials: true,
            headers: {'content-type': 'application/json', 'Authorization': token}
        })
        .then((response) => {
            if(response.data.payload.profile.is_active === false) {
                this.props.history.push({pathname: "/store-register/", state:{jwt: token, fromLogin: true}});
            }
            else {
                this.props.history.push("/dashboard/inventory/");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
      }

      this.state = {
            email: null,
            password: null
        };
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event) => {
        event.preventDefault()
        this.setState({[event.target.name]: event.target.value});
    }
  
    handleSubmit = (event) => {
        event.preventDefault();
        const data = this.state;
        console.log(this.props);
        console.log(urls.loginVendor);

        const entries = Object.entries(data);
        for(const [, value] of entries) {
            if(value === null || value === '') {
                alert("All fields are required");
                return;
            }
        }

        axios({
            method: 'post', 
            url: urls.loginVendor,
            data: {"email": data.email, "password": data.password},
            withCredentials: true,
            headers: {'content-type': 'application/json'}
        })
        .then((response) => {
            console.log(response.data);
            localStorage.setItem('token', response.data.payload.profile.token);
            this.props.sendData(true);
            if(response.data.payload.profile.is_active === false ) {
                this.props.history.push({pathname: "/store-register/", state:{jwt: response.data.payload.profile.token, fromLogin: true}});
            }
            else {
                this.props.history.push("/dashboard/inventory");
            }
            
        })
        .catch((error) => {
            console.log(error);
        });
    }

    handleClick = (event) => {
        event.preventDefault();
        document.getElementById("app").innerHTML="<RegisterForm />";
    }

  
    render() {
      return (
          <div className="login-container">
             <div className="container">
                <div className="row">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div className="card card-signin my-5 shadow-lg">
                        <div className="card-body">
                            <h5 className="card-title text-center">Login</h5>
                                <form className="form-signin" onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <div className="form-label-group">
                                            <input className="form-control" type="email" id="emailadd" value={this.state.value}
                                                    onChange={this.handleChange} placeholder="Email Address" name="email" />
                                            <label for="emailadd">Email</label>
                                        </div>
                                        <div className="form-label-group">
                                            <input className="form-control" id="password" type="password" value={this.state.value}
                                                onChange={this.handleChange} placeholder="Password" name="password" />
                                            <label for="password">Password</label>
                                        </div>
                                        
                                        <input className="btn btn-lg btn-primary btn-block btn_red text-uppercase" type="submit" value="Sign-In" />
                                        <Link to="/register/" className="btn btn-block">Don't have an account?</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          </div>
       
        
      );
    }
  }

  export default withRouter(LoginForm)
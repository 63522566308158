import React from 'react'
import axios from 'axios'
import './dashboard.css'
import DashboardHeader from '../dashboard-header/dashboard-header'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import {Page, Table, Button} from "tabler-react"
import 'font-awesome/css/font-awesome.min.css';
import {Modal} from 'react-bootstrap'
import NewProduct from './dashboard-new-product';
import * as urls from '../../urls';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';

class DashboardTable extends React.Component {
    constructor(props) {
        super(props);
        console.log("Reached dashboard-table");

        if(localStorage.getItem('token') === undefined || localStorage.getItem('token') === 'undefined') {
            console.log("Gotta sign in :(");
            this.props.history.push("/");
        }

        let token = localStorage.getItem('token')

        this.state = {
            jwt: token,
            retailer: {},
            categories: [],
            products: [],
            filterCategoryInt: 0,
            shownCategory: [],
            search: '',
            loading: true,
        };

        this.retailer = {
            retailerName: '',
            retailerImageUrl: '',
            retailerLocationName: '',
        };

        console.log(this.state); 

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.editProduct = this.editProduct.bind(this);
    }

    handleSearch = (event) => {
        this.setState({search: event.target.value})
    }

    filtercategory = (event) => {
        console.log(event.target.value)
        if(event.target.value === undefined || event.target.value ==='') {
            this.setState({shownCategory: this.state.categories})
        }
        else {
            let cats = this.state.categories[event.target.value]
            console.log([cats])
            this.setState({shownCategory: [cats]})
            console.log(this.state.shownCategory)
        }
        
    }

    newProduct = (event) => {
        this.props.history.push({pathname: '/dashboard/product/'});
    }

    editProduct = (product, category) => {
        var query = "?product="+product;
        this.props.history.push({pathname: '/dashboard/product/', search: query})
    }

    handleChange = (event) => {
        event.preventDefault()
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit = (event) => {
        event.preventDefault();
    }

    componentWillMount() {
        console.log(this.props);
        var token = localStorage.getItem('token');
        axios({
            method: 'get', 
            url: urls.getVendorInfo,
            withCredentials: true,
            headers: {'content-type': 'multipart/form-data', 'Authorization': token}
        })
        .then((response) => {
            console.log(response.data);
            if(response.data.payload.profile.is_active === false) {
                this.props.history.push({pathname: "/store-register/", state:{jwt: token}});
            }
            this.setState({loading: false, retailer: {
                retailerName: response.data.payload.profile.firstname + " " + response.data.payload.profile.lastname,
                retailerImage: "https://api-sandbox.gophr.app/"+ response.data.payload.profile.store_logo,
                locationName: response.data.payload.profile.location_name,
            }, categories: response.data.payload.profile.categories, shownCategory: response.data.payload.profile.categories});
            console.log(this.state);
        })
        .catch(error => {
            console.log(error);
            this.props.history.push('/');
        });

    }
    
    render() {
        return (
            <div>
                <div className="page-header">
                    <h1 className="page-title">Hello {this.state.retailer.retailerName}!</h1>
                </div>

                <div className="row">
                    <div className="col col-12">
                        <div className="float-right">
                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Import Data
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" href="#">Download Template</a>
                                    <a className="dropdown-item" href="#">Upload XLS</a>
                                </div>
                            </div>
                            <button className="btn btn-primary dashboard-button" onClick={ this.newProduct } style={{marginLeft: 20}}>Add Product</button>
                        </div>
                        
                    </div>
                </div>
                <br></br>
                {(this.state.categories.length == 0 && this.state.loading == false) &&
                    <div className="alert alert-primary" role="alert">
                        Add a product to begin!
                    </div>
                }
                <div className="col col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row" style={{width: '100%', marginTop: 5}}>
                                <div className="col-sm">
                        
                                    <select className="btn btn-secondary" value={this.state.value} onChange={this.filtercategory} name="filterCategoryInt" >
                                        <option value="">All Products</option>
                                        {this.state.categories.map((value, index) => {
                                            return <option value={index}>{value.name}</option>
                                        })}
                                    </select> 
                                </div>
                                
                                <div className="col-sm">
                                    
                                        <input style={{width: 250, height: 'auto', float: "right", border: 0, outline: 0, 
                                        background: 'transparent', borderBottom: 1, borderBottomColor: 'gray', borderBottomStyle:'solid', paddingBottom: 4}} type="text" 
                                        placeholder="Search SKU or Product Name" aria-label="Search" name="search" value={this.state.value} onChange={this.handleSearch}/>
                                </div>
                                
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table card-table table-vcenter">
                                <thead className="">
                                    <tr className="">
                                        <th style={{width: '10%'}}>Image</th>
                                        <th style={{width: '10%'}}>SKU</th>
                                        <th style={{width: '12%'}}>Name</th>
                                        <th style={{width: '20%'}}>Description</th>
                                        <th style={{width: '12%'}}>Category</th>
                                        <th style={{width: '10%'}}>QTY</th>
                                        <th style={{width: '10%'}}>Price</th>
                                        <th style={{width: '10%'}}>Options</th>
                                    </tr>
                                </thead>
                                    {this.state.loading && (
                                        <div style={{position: 'absolute', left: 0, right: 0, width: '100%', height: '100%', backgroundcolor: 'blue', textAlign: 'center'}}>
                                            <ClipLoader
                                            sizeUnit={"px"}
                                            size={100}
                                            color={'#F7941D'}
                                            loader
                                            loading={this.state.loading}
                                            />
                                        </div> 
                                    )}
                                
                                    {this.state.shownCategory.map((value, index) => 
                                        {if(value.products.length !== 0) {
                                            return(
                                            <tbody>
                                                {value.products.map((product, i) => {
                                                    if(this.state.search !== '') {
                                                        if(product.name.toLowerCase().includes(this.state.search.toLowerCase()) || product.sku.toLowerCase().includes(this.state.search.toLowerCase())) {
                                                            if(product.variantOf == null) {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            <img alt="" src={'http://localhost:8000' + product.image} className="h-8"/>
                                                                        </td>
                                                                        <td>
                                                                            {product.sku}
                                                                        </td>
                                                                        <td>
                                                                            {product.name}
                                                                        </td>
                                                                        <td>
                                                                            {product.description}
                                                                        </td>
                                                                        <td>
                                                                            {value.name}
                                                                        </td>
                                                                        <td>
                                                                            {product.quantity}
                                                                        </td>
                                                                        <td>
                                                                            ${product.price}
                                                                        </td>
                                                                        <td>
                                                                            <button className="btn btn-secondary" onClick={() => this.editProduct(product.id, value.id)}><i className="fa fa-edit"></i> Edit</button>
                                                                        </td>
                                                                    </tr>)
                                                            }
                                                            
                                                        }
                                                    } else {
                                                        if(product.variantOf == null) {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <img alt="" src={'http://localhost:8000' + product.image} className="h-8"/>
                                                                    </td>
                                                                    <td>
                                                                        {product.sku}
                                                                    </td>
                                                                    <td>
                                                                        {product.name}
                                                                    </td>
                                                                    <td>
                                                                        {product.description}
                                                                    </td>
                                                                    <td>
                                                                        {value.name}
                                                                    </td>
                                                                    <td>
                                                                        {product.quantity}
                                                                    </td>
                                                                    <td>
                                                                        ${product.price}
                                                                    </td>
                                                                    <td>
                                                                        <button className="btn btn-secondary" onClick={() => this.editProduct(product.id, value.id)}><i className="fa fa-edit"></i> Edit</button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        
                                                    }
                                                    

                                                    
                                                })}
                                            </tbody>
                                            )}}
                                    )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardTable
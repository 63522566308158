import React from 'react'
// import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import RegisterForm from './pages/register/register.jsx'
import LoginForm from './pages/login/login.jsx'
import StoreRegister from './pages/register/store-register'
import Dashboard from './pages/dashboard/dashboard';
import DashboardHeader from './pages/dashboard-header/dashboard-header'
import "tabler-react/dist/Tabler.css";
import axios from "axios"
import NewProduct from './pages/dashboard/dashboard-new-product.jsx';

const refreshTokenUrl = "http://localhost:8000/api/token/refresh/"

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      requestExtension: false,
      index: 0,
    }
    console.log("app component called")
    var token = localStorage.getItem("token")
    if(token !== null || token !== 'undefined') {
      setInterval(this.loadData, 1740000)
    }

    this.handleLogin = this.handleLogin.bind(this);
  }

  handleLogin(value) {
    if(value === true) {
      console.log("called")
      setInterval(this.loadData, 1740000);
    }
    
  }

  async loadData() {
    if(this.state === undefined) {
      console.log("started")
      var token = localStorage.getItem("token")

      if(token !== null || token !== 'undefined') {
        axios({
          method: 'post', 
          url: refreshTokenUrl,
          data: {"token": token},
          withCredentials: true,
          headers: {'content-type': 'application/json'}
          })
          .then((response) => {
              localStorage.setItem('token', response.data.token);
              
          })
          .catch((error) => {
              console.log(error);
          });
        }
      }
      
  
 }

  render() {
    return (
      <div id="app" className="App">
        <Router>
          <Route path="/register/" component={ RegisterForm } />
          {/*<Route path="/" exact component={ LoginForm } sendData={this.handleLogin} />*/}
          <Route
            path='/'
            exact
            render={(props) => <LoginForm {...this.props} sendData={this.handleLogin} />}
          />
          <Route path="/store-register/" component= { StoreRegister } />
          <Route path="/dashboard/" component= { Dashboard } />
        </Router>
      </div>
      
    );
  }
}

export default App;
export const host = "https://api-sandbox.gophr.app";
//Local ip
// export const port = "8000";
// export const ip = host + ":" + port;
export const ip  = host;
export const registerVendor = ip + "/vendor/register";
export const registerStore = ip + "/vendor/create-location";
export const loginVendor = ip + "/vendor/login";
export const getVendorInfo = ip + "/vendor/get";
export const addCategory = ip + "/vendor/category/add";
export const editCategory = ip + "/vendor/category/edit";
export const deleteCategory = ip + "/vendor/category/delete";
export const addProduct = ip + "/vendor/product/add";
export const editProduct = ip + "/vendor/product/edit";
export const deleteProduct = ip + "/vendor/product/delete";
export const getProduct = ip + "/vendor/product/get";
export const getAllOrders = ip + "/vendor/orders";
export const updateVariantUrl = ip + "/products/edit-variant";
export const deleteVariantUrl = ip + "/products/delete-variant";
export const deleteProductUrl = ip + "/vendor/product/delete";

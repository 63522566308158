import React from 'react'
// import App from '../../App'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import axios from 'axios'
import "tabler-react/dist/Tabler.css";
import {
    Nav, AccountDropdown
  } from "tabler-react";
  import './dashboard-header.css'
  

// document.getElementById("toRegister").addEventListener("click", function() {
//     document.getElementById({App}).innerHTML = "<RegisterForm />"
// });
const url = "http://localhost:8000/vendor/login/"
const retailerInfoUrl = "http://localhost:8000/vendor/get/"

class DashboardHeader extends React.Component {
    constructor(props) {
        super(props);

        this.props = props;
    
        //console.log(props);

        if(localStorage.getItem('token') === undefined || localStorage.getItem('token') === 'undefined') {
            this.props.history.push('/')
        }

        this.state = {
            activeTab: 'inventory'
            };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    handleChange = (event) => {
        event.preventDefault()
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        
    }

    toInventory() {
        this.setState({activeTab: 'inventory'})
        this.props.history.push({pathname: '/dashboard/inventory/'});
    }

    toOrders() {
        this.setState({activeTab: 'orders'})
        this.props.history.push({pathname: '/dashboard/orders/'});
    }

    signOut() {
        localStorage.setItem('token', 'undefined');
        this.props.history.push('/');
    }


    render() {
        console.log(this.props.activeTab)
        return (
            <div className="page">
                <div className="page-main">
                    <div className="header py-4 header-top">
                        <div className="container">
                            <div className="d-flex">
                                <a className="header-brand" href="/dashboard/inventory">
                                    <img className="header-brand-img" src="https://i.ibb.co/F3YY69B/gophr-logo2.png" style={{objectFit: 'fill'}} alt="Gophr"></img>
                                </a>
                                <div className="d-flex order-lg-2 ml-auto">
                                    <div className="dropdown">
                                        <AccountDropdown
                                            avatarURL={this.props.retailer.retailerImage}
                                            name={this.props.retailer.retailerName}
                                            description={this.props.retailer.locationName}
                                            options={[
                                                "profile",
                                                { icon: "settings", value: "Settings", to: "/settings" },
                                                "mail",
                                                "message",
                                                "divider",
                                                "help",
                                                {icon: "log-out", value: "Log Out",  onClick: () => this.signOut()},
                                            ]}
                                            />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="header d-lg-flex p-0 collapse">
                        <div className="container" style={{fontSize: 16}}>
                            <Nav>
                                <Nav.Item onClick={() => {this.toInventory();}} active={this.state.activeTab === 'inventory' ? true: false} icon="box">Inventory</Nav.Item>
                                <Nav.Item onClick={() => {this.toOrders();}} active={this.state.activeTab === 'orders' ? true: false} icon="shopping-bag">Orders</Nav.Item>
                            </Nav>
                        </div>
                    </div>

                </div>
            </div>
            
        );
    }
  }

  export default DashboardHeader
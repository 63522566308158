import React from 'react'
// import { isArgumentPlaceholder, breakStatement } from '@babel/types'
// import { HashRouter, Switch, Link, Route, Redirect } from 'react-router-dom'
import axios from 'axios'
import './register.css'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import * as urls from '../../urls'

class RegisterForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
            fname: null,
            lname: null,
            email: null,
            phone: null,
            password: null,
            cpassword: null,
            role: "retailer",
        };

        var repsonse = null;
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event) => {
        event.preventDefault()
        this.setState({[event.target.name]: event.target.value});
    }
  
    handleSubmit = (event) => {
        event.preventDefault();
        const data = this.state;

        const entries = Object.entries(data);
        for(const [, value] of entries) {
            if(value === null || value === '') {
                alert("All fields are required");
                return;
            }
        }
        if(data.password !== data.cpassword) {
            alert("Your password must match");
            return;
        }

        var body = {'firstname': data.fname, 'lastname': data.lname, 'email': data.email,
                    'password': data.password, 'phone': data.phone, 'role': data.role};
        
        axios({
            method: 'post', 
            url: urls.registerVendor,
            data: body,
            config: {headers: {'Content-Type': 'application/json'}}
        })
        .then((response) => {
            console.log(response.data);
            let jwt = response.data.payload.profile.token;
            this.props.history.push({pathname: "/store-register/", state:{jwt: jwt}});
        })
        .catch(function (error) {
            console.log(error);
        });
    }
  
    render() {
      return (
        <div className="register-container">
            <div className="container">
                <div className="row">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto" style={{marginTop: `-142px`}}>
                    <div className="card card-register my-5 shadow-lg">
                        <div className="card-body">
                            <h5 className="card-title text-center">Welcome</h5>
                                <form className="form-signin" onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <div className="form-label-group">
                                            <input className="form-control" id="fname" type="text" value={this.state.value}
                                                    onChange={this.handleChange} placeholder="First Name" name="fname" />
                                            <label for="fname">First Name</label>
                                        </div>
                                        <div className="form-label-group">
                                            <input className="form-control" type="text" id="lname" value={this.state.value} 
                                                onChange={this.handleChange} placeholder="Last Name" name="lname" />
                                            <label for="lname">Last Name</label>
                                        </div>
                                        <div className="form-label-group">
                                            <input className="form-control" type="email" id="emailadd" value={this.state.value}
                                                    onChange={this.handleChange} placeholder="Email Address" name="email" />
                                            <label for="emailadd">Email</label>
                                        </div>
                                        <div className="form-label-group">
                                            <input className="form-control" type="tel" id="phone" value={this.state.value}
                                                    onChange={this.handleChange} placeholder="Phone Number" name="phone" pattern="[0-9{3}-[0-9]{3}-[0-9]{4}"/>
                                            <label for="phone">Personal Phone</label>
                                        </div>
                                        <div className="form-label-group">
                                            <input className="form-control" id="password" type="password" value={this.state.value}
                                                onChange={this.handleChange} placeholder="Password" name="password" />
                                            <label for="password">Password</label>
                                        </div>
                                        
                                        <div className="form-label-group">
                                            <input className="form-control" id="cpassword" type="password" value={this.state.value}
                                                onChange={this.handleChange} placeholder="Confirm Password" name="cpassword" />
                                            <label for="cpassword">Confirm Password</label>
                                        </div>

                                        {/* <div className="form-label-group">
                                            <input className="form-control" id="storename" type="text" value={this.state.value} 
                                                onChange={this.handleChange} placeholder="Store Name" name="storename" />
                                            <label for="storename">Store Name</label>
                                        </div>

                                        <div className="form-label-group">
                                            <input className="form-control" id="storephone" type="tel" value={this.state.value}
                                                onChange={this.handleChange} placeholder="Store Phone Number" name="storephone" />
                                            <label for="storephone">Store Phone</label>
                                        </div>

                                        <div className="form-label-group">
                                            <input className="form-control" id="address" type="text" value={this.state.value} 
                                                onChange={this.handleChange} placeholder="Store Address" name="address" />
                                            <label for="address">Store Address</label>
                                        </div> */}
                                        
                                            <button className="btn btn-lg btn-primary btn-block btn_red text-uppercase" type="submit">Next</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

        
      );
    }
  }

  export default RegisterForm